import * as React from 'react'
import Layout from '../components/layout'
import {
  pageBody,
  legalPage,
} from '../components/layout.module.scss'
import Seo from '../components/seo'

export default function PrivacyPage() {
  return (
    <Layout pageTitle="Privacy Policy">
    <Seo 
      title="Privacy Policy"
      description="Privacy Policy for BRADIENT LLC" 
    />
      <div className={pageBody}>
        <div className={legalPage}>
        <h2>Privacy Notice.</h2>
        <p>Last Updated: December 25, 2022.</p>
        <p>BRADIENT LLC. &nbsp;and its affiliates (&ldquo;BRADIENT&rdquo; or &ldquo;we&rdquo;) respect your concerns about privacy. This Website Privacy Notice describes the types of information we collect about individuals on https://www.bradient.com (the &ldquo;Site&rdquo;), how we use the information, with whom we share it, and the choices available regarding our use of the information. The Website Privacy Notice also describes the measures we take to safeguard personal information, how long we retain it, and how individuals can contact us about our privacy practices and to exercise their rights.</p>
        <p><em>Information</em> We Obtain&nbsp;</p>
        <p><em>Information</em> You Provide</p>
        <p>We may collect and store personal information (such as name and other contact details) that you choose to provide to us through the Site or when you contact us. &ldquo;Personal Information&rdquo; is information that identifies you as an individual or relates to an identifiable individual. The types of personal information we collect may include:</p>
        <ol>
            <li>
                <p>Contact information, such as your name, e-mail address, and telephone number;</p>
            </li>
            <li>
                <p>When you apply for a job, your name, email address, phone number, gender, race, and any other information you may provide in your cover letter or resume; and</p>
            </li>
            <li>
                <p>Other personal information you may submit to us. and</p>
            </li>
            <li>
                <p>Other personal information you may submit to us, such as when you contact us.</p>
            </li>
        </ol>
        <p><em>Information We Receive About You from Other Sources</em></p>
        <p>We may receive your Personal Information from other sources, such as publicly available databases and providers of demographic information.</p>
        <p><em>Third-Party Web Analytics Services</em></p>
        <p>We use third-party online analytics services, such as Google Analytics. The service providers that administer these analytics services use cookies and similar technologies to collect and analyze information about use of the Site and report on activities and trends and diagnose technical issues. To learn more about Google&rsquo;s practices, please visit https://policies.google.com/technologies/partner-sites, and opt out of them by downloading the Google Analytics Opt-out Browser Add-on, available at https://tools.google.com/dlpage/gaoptout.</p>
        <p><strong>How We Use Personal Information</strong></p>
        <p>We use the Personal Information we obtain on the Site for legitimate business purposes, including to:</p>
        <ol>
            <li><p>Provide our services to you; process, evaluate, and respond to your requests and inquiries; and send administrative information to you, such as changes to our terms, conditions, and policies. We will engage in these activities to manage our contractual relationship with you and/or to comply with a legal obligation.</p></li>
            <li><p>Market our services to you. We will engage in this activity with your consent or where we have a legitimate interest.</p></li>
            <li><p>Operate, evaluate, and improve our business (including developing new services; enhancing and improving our services; analyzing our services; managing our communications; performing data analytics and market research; and performing accounting, auditing, and other internal functions). We will engage in these activities to comply with a legal obligation and/or because we have a legitimate interest.</p></li>
            <li><p>Protect against, identify, and prevent fraud, security breaches, and other criminal activity and risks. We will engage in these activities to comply with a legal obligation and/or because we have a legitimate interest.</p></li>
            <li><p>Comply with and enforce applicable legal requirements, relevant industry standards, and our policies, including this Website Privacy Notice and/or BRADIENT&rsquo;s Terms of Use. We will engage in these activities to comply with a legal obligation and/or because we have a legitimate interest.</p></li>
            <li><p>Aggregate and/or anonymize Personal Information so that it will no longer be considered personal information. We do so to generate other data for our use, which we may use and disclose for any purpose.</p></li>
        </ol>
        <p><strong>Personal Information Sharing</strong></p>
        <p>We share your Personal Information with:</p>
        <ol>
            <li><p>BRADIENT LLC., (&ldquo;BRADIENT&rdquo;), for the purposes described in this Website Privacy Notice.</p></li>
            <li><p>Our service providers who perform services on our behalf, such as hosting this Site or data analytics. We do not authorize our service providers to use or disclose the information except as necessary to perform services on our behalf or comply with legal requirements.</p></li>
        </ol>
        <p>We also may disclose information about you (1) if we are required to do so by law or legal process (such as a court order or subpoena), including laws outside your country of residence; (2) in response to requests by, or cooperate with, government agencies, such as law enforcement authorities, including outside of your country of residence; (3) to establish, exercise, or defend our legal rights; (4) when we believe disclosure is necessary or appropriate to protect our rights, privacy, safety, or property and/or that of our affiliates, you, or others; (5) in connection with an investigation of suspected or actual illegal activity; (6) in connection with the sale or transfer of all or a portion of our business, assets, or stock (including in the event of a reorganization, dissolution, or liquidation); or (7) otherwise with your consent.</p>
        <p><strong>Cross-Border Transfer</strong></p>
        <p>Your personal information may be processed and stored in any country where we have facilities or in which we engage service providers, and, by using the Site, you understand that your information may be transferred to countries outside of your country of residence, including the United States, which may have data protection rules that are different from those of your country. In certain circumstances, courts, law enforcement agencies, regulatory agencies, or security authorities in those other countries may be entitled to access your personal information.</p>
        <p>ADDITIONAL INFORMATION REGARDING THE EUROPEAN ECONOMIC AREA (&ldquo;EEA&rdquo;): Some non-EEA countries are recognized by the European Commission as providing an adequate level of data protection according to EEA standards (the full list of these countries is available here). For transfers from the EEA to countries not considered adequate by the European Commission, we have put in place adequate measures, such as standard contractual clauses adopted by the European Commission to protect your personal information. You may obtain a copy of the EU&rsquo;s Standard Contractual Clauses here.</p>
        <p><strong>Your Rights and Choices</strong></p>
        <p>We offer you certain choices in connection with the personal information we obtain about you. If you would like to request to review, correct, update, suppress, restrict, or delete personal information, to object to the processing of personal information, or to receive an electronic copy of your personal information for purposes of transmitting it to another company (to the extent these rights are provided to you by applicable law), please contact us as specified in the How to Contact Us section of this Website Privacy Notice. We will respond to your request consistent with applicable law.</p>
        <p>To help protect your privacy and maintain security, we may take steps to verify your identity before granting you access to your information. To the extent permitted by applicable law, a charge may apply before we provide you with a copy of any of your personal information that we maintain.</p>
        <p>You can unsubscribe from any marketing mailing lists we may maintain by following the &ldquo;Unsubscribe&rdquo; link in our marketing emails. If you would like to unsubscribe from any other type of marketing you receive from us, please contact us as specified in the How to Contact Us section of this Website Privacy Notice.&nbsp;</p>
        <p><strong>Other Online Services and Third-Party Features</strong></p>
        <p>The Site may provide links to other online services and websites for your convenience and information, and may include third-party features such as apps, tools, widgets, and plug-ins (e.g., LinkedIn, Instagram, Facebook, and Twitter buttons). These services and websites may operate independently from us. The privacy practices of these third parties, including details on the information they may collect about you, are subject to their own privacy statements. To the extent any linked online services or third-party features are not owned or controlled by us, BRADIENT is not responsible for these third parties&rsquo; information or other practices.</p>
        <p><strong>Retention of Personal Information</strong></p>
        <p>We retain personal information for as long as needed or permitted in light of the purposes for which we obtained it and consistent with applicable law. The criteria used to determine our retention periods include:</p>
        <ol>
            <li><p>The length of time we have an ongoing relationship with you and provide the Site or our services to you;</p></li>
            <li><p>Whether there is a legal obligation to which we are subject; and</p></li>
            <li><p>Whether retention is advisable in light of our legal position (such as in regard to applicable statutes of limitation, litigation, or regulatory investigations).</p></li>
        </ol>
        <p><strong>How We Protect Personal Information</strong></p>
        <p>We seek to use reasonable administrative, technical and physical safeguards designed to protect personal information within our organization. We hold our employees accountable for complying with policies, procedures, and regulations regarding the protection and confidentiality of personal information. If you have reason to believe that your interaction with us is no longer secure, please immediately notify us in accordance with the How to Contact Us section of this Website Privacy Notice.</p>
        <p><strong>Children&rsquo;s Personal Information</strong></p>
        <p>The Site is designed for a general audience and is not directed to children under the age of 16. BRADIENT does not knowingly collect or solicit personal information from children under the age of 16 through the Site. If you believe that a child under the age of 16 may have provided us with personal information through the Site, please contact us as specified in the How to Contact Us section of this Website Privacy Notice.</p>
        <p><strong>Updates to Our Website Privacy Notice</strong></p>
        <p>This Website Privacy Notice may be updated periodically and without prior notice to you to reflect changes in our personal information practices. We will indicate at the top of the notice when it was most recently updated. Any changes will become effective when we post the revised Website Privacy Notice on the Site.</p>
        <p><strong>How to Contact Us</strong></p>
        <p>BRADIENT is located at 427 W 5th St. Los Angeles, CA 90013, is the company responsible for collection, use, and disclosure of your personal information under this Website Privacy Notice.</p>
        <p>To contact us in relation to this Privacy Notice or any privacy matter, including our use of your personal information or to exercise other applicable privacy rights, please use the following contact details:</p>
        <ul>
            <li>
                <p>ATTN: BRADIENT 427 W 5th St. Los Angeles, CA 90013</p>
                <p><a href="mailto:hello@bradient.com">hello@bradient.com</a></p>
            </li>
        </ul>
        <p>You have the right to lodge a complaint with a data protection authority for your country or region or where an alleged infringement of applicable data protection law occurs. A list of data protection authorities is available <a href="https://ec.europa.eu/newsroom/article29/items/612080/en">here</a>.</p>
        <h2>Terms of use.</h2>
        <p>Last Updated: December 25, 2022</p>
        <p>Introduction.</p>
        <p>BRADIENT, LLC (&ldquo;BRADIENT&rdquo;) provides to you this website (the &ldquo;Site&rdquo;) for your personal informational, educational, and entertainment use. The following Terms of Use govern your access and use of this Site. Please read these Terms of Use carefully before using this Site. Also, specific terms may apply to specific Content (as defined below) on or available through this Site and to specific content, data, materials, or information that you may upload, submit, and/or publish to the Site (&ldquo;User Content&rdquo;) or to transactions conducted through this Site. Such specific terms may be in addition to these Terms of Use or, to the extent expressly specified, may supersede these Terms of Use. When used in these Terms of Use, &ldquo;we&rdquo; and &ldquo;our&rdquo; means BRADIENT, and &ldquo;you&rdquo; and &ldquo;your&rdquo; refers to any individual, company or legal entity that accesses or otherwise uses this Site.</p>
        <ol>
            <li>
                <p>Acceptance of Terms of Use.</p>
                <ul>
                    <li>
                        <p>By accessing, browsing, or using this Site, you agree to be bound by these Terms of Use or any additional terms and conditions on this Site. If you do NOT agree to all of these terms, you should NOT access or use this Site.</p>
                    </li>
                </ul>
            </li>
            <li>
                <p>Modification of Terms.</p>
                <ul>
                    <li>
                        <p>These Terms of Use may be amended by BRADIENT, in its sole discretion, at any time without notice to you. Such amended Terms of Use shall be effective upon posting. By continuing to access or use this Site, you will be deemed to have accepted such amendments. You are advised to regularly review any applicable terms and conditions. BRADIENT reserves the right to discontinue or make changes or updates with respect to the Site or the Content of the Site at any time without notice.</p>
                    </li>
                </ul>
            </li>
            <li>
                <p>Privacy Notice.</p>
                <ul>
                    <li>
                        <p>Personal information provided through this Site is used in accordance with BRADIENT&rsquo;s Privacy Notice above.</p>
                    </li>
                </ul>
            </li>
            <li>
                <p>Copyright and Trademarks.</p>
                <ul>
                    <li>
                        <p>You acknowledge that all content on this Site, including the Site&rsquo;s design, graphics, text, formatting, sounds, pictures, images, software, and other materials and information on this Site, and the selection and arrangement of such content (collectively, &ldquo;Content&rdquo;), are the property of BRADIENT or its licensors, and are subject to and protected by United States and international copyright and other intellectual property laws and rights. All rights to Content not expressly granted in these Terms of Use are reserved to their respective intellectual property right owners. Except as expressly authorized in these Terms of Use or on the Site, you may not copy, reproduce, distribute, republish, download, perform, display, post, transmit, exploit, create derivative works or otherwise use any of the Content in any form or by any means, without the prior written authorization of BRADIENT or the respective intellectual property rights owner. BRADIENT authorizes you to view and download the Content only for personal, non-commercial use, provided that you keep intact all copyright and other proprietary notices contained in the original Content. You may not modify or adapt the Content in any way or otherwise use them for any public or commercial purposes. The trademarks, service marks, trade names, trade dress and logos (collectively, &ldquo;Marks&rdquo;) contained or described on this Site (including, without limitation, BRADIENT) are the sole property of BRADIENT and/or its licensors and may not be copied, imitated or otherwise used, in whole or in part, without the prior written authorization of BRADIENT and/or its licensors. In addition, all page headers, custom graphics, button icons and scripts are Marks of BRADIENT and may not be copied, imitated or otherwise used, in whole or in part, without the prior written authorization of BRADIENT. BRADIENT will enforce its intellectual property rights to the fullest extent of the law.</p>
                    </li>
                </ul>
            </li>
            <li>
                <p>Links to Third-Party Websites.</p>
                <ul>
                    <li>
                        <p>Links on the Site to third-party websites are provided only as a convenience to you. If you use these links, you will leave the Site. BRADIENT does not control or endorse any such third-party websites. You agree that BRADIENT and its affiliates will not be responsible or liable for any content, goods or services provided on or through these third-party websites or for your use of, or inability to use, such websites. You will use these links at your own risk. You are advised that other websites on the Internet, including third party websites linked from this Site, might contain material or information that some people may find offensive or inappropriate; or that is inaccurate, untrue, misleading or deceptive; or that is defamatory, libelous, infringing of others&rsquo; rights or otherwise unlawful. BRADIENT expressly disclaims any responsibility for the content, legality, decency or accuracy of any information, and for any products and services, that appear on any third-party website. BRADIENT recommends that you make yourself aware of and read the legal and privacy notices of all other websites that you visit.</p>
                    </li>
                </ul>
            </li>
            <li>
                <p>Disclaimer of Warranties.</p>
                <ul>
                    <li>
                        <p>BRADIENT strives to provide accurate and up-to-date material on this Site. However, we make no warranties or representations as to the accuracy or timeliness of the Content on this Site. YOUR USE OF THE SITE IS AT YOUR SOLE RISK. THE SITE AND THE CONTENT CONTAINED ON THIS SITE ARE PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS. BRADIENT EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. BRADIENT MAKES NO WARRANTY THAT (I) THE SITE WILL MEET YOUR REQUIREMENTS, (II) THE SITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SITE WILL BE ACCURATE OR RELIABLE, (IV) THE SITE IS FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS, OR (V) ANY ERRORS IN THE SITE WILL BE CORRECTED. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THIS SITE IS PROVIDED AT YOUR OWN RISK, AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM YOUR USE OF THE SITE, INCLUDING WITHOUT LIMITATION, DAMAGES RESULTING FROM COMPUTER VIRUSES. SOME JURISDICTIONS MAY NOT PERMIT CERTAIN DISCLAIMERS OF WARRANTIES, SO SOME OF THE EXCLUSIONS ABOVE MAY NOT APPLY TO YOU. IN SUCH JURISDICTIONS, WE DISCLAIM WARRANTIES TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.</p>
                    </li>
                </ul>
            </li>
            <li>
                <p>Disclaimer of Damages and Limitation of Liability.</p>
                <ul>
                    <li>
                        <p>NEITHER BRADIENT OR ITS AFFILIATES SHALL UNDER ANY CIRCUMSTANCES BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING OUT OF, IN CONNECTION WITH OR RELATING TO YOUR ACCESS TO, OR USE OF OR INABILITY TO USE THIS SITE OR ANY MATERIAL, OR ANY OTHER WEBSITE YOU ACCESS THROUGH A LINK FROM THIS SITE, OR ANY INCORRECT OR INACCURATE INFORMATION ON THIS SITE. THIS IS A COMPREHENSIVE LIMITATION OF LIABILITY THAT APPLIES TO ALL DAMAGES OF ANY KIND, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING BUT NOT LIMITED TO PROPERTY DAMAGE, LOSS OF USE, LOSS OF DATA, LOSS OF BUSINESS, ECONOMIC LOSS OR LOSS OF PROFITS), WHETHER BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, EVEN IF BRADIENT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE OR LOSS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU EXPRESSLY WAIVE ALL CLAIMS AGAINST BRADIENT AND ITS AFFILIATES AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, SUPPLIERS AND PROGRAMMERS THAT MAY ARISE FROM YOUR ACCESS OR USE OF THIS SITE.</p>
                    </li>
                </ul>
            </li>
            <li>
                <p>Indemnification and Release.</p>
                <ul>
                    <li>
                        <p>You agree to indemnify, defend and hold harmless BRADIENT and its affiliates against all claims, demands, causes of action, losses, expenses, damages and costs, including any reasonable attorneys&rsquo; fees, resulting or arising from or relating to your use of or conduct on the Site; any activity related to use of the Site by you; any message or material that you submit to, post on, or transmit through the Site; your violation of these Terms of Use; your infringement or violation of any rights of another; or termination of your access to the Site. If you have a dispute with one or more users, you release BRADIENT and its affiliates from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.</p>
                    </li>
                </ul>
            </li>
            <li>
                <p>No Unlawful or Prohibited Activity.</p>
                <ul>
                    <li>
                        <p>As a condition of your use of this Site, you agree not to use the Site for any purpose that is unlawful or prohibited by these terms and conditions. You further agree that you are responsible for your use of and communications on the Site. You agree not to post on or transmit through this Site any unlawful, infringing, threatening, harassing, defamatory, vulgar, obscene, profane, indecent, offensive, hateful or otherwise objectionable material of any kind, including any material that encourages criminal conduct or conduct that would give rise to civil liability, violates the privacy rights of others, infringes others&rsquo; intellectual property rights or otherwise violates any applicable local, state, national or international law. You agree not to use this Site in any manner that interferes with its normal operation or with any other user&rsquo;s use and enjoyment of the Site. You agree to use reasonable efforts to scan and remove any viruses or other contaminating or destructive features before submitting any material through or to the Site. BRADIENT reserves the right, in its sole discretion, to suspend or terminate your access to this Site and prohibit any and all current and future use of this Site (or any portion of the Site) by you, if you fail to comply with any term or provision of these Terms of Use or if your use is harmful to the interests of another user of this Site.</p>
                    </li>
                </ul>
            </li>
            <li>
                <p>Shut-down of Site.</p>
                <ul>
                    <li>
                        <p>BRADIENT reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Site (or any part of the Site) with or without notice or consent. BRADIENT and its affiliates shall have no responsibility or liability for failure to store or delete any Content or User Content submitted to this Site.</p>
                    </li>
                </ul>
            </li>
            <li>
                <p>Governing Law and Dispute Resolution.</p>
                <ul>
                    <li>
                        <p>These Terms of Use shall be governed by the laws of the United States and the State of California, without giving effect to conflicts of law principles that would cause other laws to apply. BRADIENT makes no representations that the material and information on this Site are appropriate or available in all national locations or languages. You agree that any action at law or in equity arising from or relating to your access or use of the Site or to these Terms of Use shall be brought exclusively in the federal or state courts residing in California. You hereby consent and submit to personal jurisdiction of such courts for the purposes of any action relating to your access or use of the Site or these Terms of Use, and to extraterritorial service of process.</p>
                    </li>
                </ul>
            </li>
            <li>
                <p>Severability.</p>
                <ul>
                    <li>
                        <p>If any provision of these Terms of Use is held to be unlawful, void, or for any reason unenforceable by a court of competent jurisdiction, then such provision shall be replaced by a valid, enforceable provision that most closely matches the intent of the original provision, and the validity and enforceability of any remaining provisions shall not be affected.</p>
                    </li>
                </ul>
            </li>
            <li>
                <p>No Waiver.</p>
                <ul>
                    <li>
                        <p>The failure of BRADIENT or its affiliates to enforce any provision of these Terms of Use shall not constitute a waiver of that provision and shall not waive or limit BRADIENT&rsquo;s right to subsequently enforce that provision or any other provision of these Terms of Use.</p>
                    </li>
                </ul>
            </li>
        </ol>
        <h2>Accessibility Policy and Commitment Statement.</h2>
        <p>BRADIENT is committed to interacting, communicating and providing goods and services to all customers and members of the public that are based on the full inclusion of all persons with disabilities. We recognize people with disabilities may have different needs and we will, in accordance with government guidelines, support the identification, removal and prevention of barriers for persons with disabilities. Our goal is to deliver an excellent user experience for all types of viewers, using different types of mobile and desktop devices, and those using assistive technology. Our efforts to improve and optimize the user experience for accessibility are ongoing as we develop new areas of our website, and as technology changes. If at any point in your browsing experience you have feedback for us to improve our user experience or accessibility support, please contact us at <a href="mailto:hello@bradient.com">hello@bradient.com</a>. If you wish to provide BRADIENT with feedback regarding the manner in which we provided our service, please choose one of the feedback options below that best addresses your needs.</p>
        <p>To contact us by phone: <a href="tel:844-454-4218">(844) 454-4218</a></p>
        <p>To send us an email: <a href="mailto:hello@bradient.com">hello@bradient.com</a></p>
        <p>To contact us by mail, please address your correspondence as follows:</p>
        <ul>
            <li>
                <p>BRADIENT 427 W 5th St, Los Angeles, CA 90013</p>
                <p><a href="mailto:hello@bradient.com">hello@bradient.com</a></p>
            </li>
        </ul>
        <h2>California Consumer Privacy Act Privacy Notice.</h2>
        <p>CCPA.</p>
        <p>BRADIENT, LLC and its affiliates (&ldquo;BRADIENT,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;) provide this Privacy Notice, which applies to California resident employees, employee applicants, owners, directors, officers, or contractors of BRADIENT, including as applicable, those individuals&rsquo; emergency contacts and beneficiaries. This Privacy Notice sets forth our privacy practices as required by the CCPA. Please note, there may be additional or different privacy notices or disclosures that govern our use of your personal information beyond the CCPA. Where collection, use and disclosure of personal information is subject to the CCPA, this Privacy Notice shall prevail. For collection, use and disclosure of personal information beyond the CCPA, those separate and relevant privacy notices and disclosures shall prevail. Any terms defined in the CCPA have the same meaning when used in this notice.</p>
        <p>Pursuant to the California Consumer Privacy Act of 2018 (&ldquo;CCPA&rdquo;), we provide the following details regarding the categories of Personal Information we have collected or disclosed within the preceding twelve (12) months:</p>
        <p>(1) We collected the following categories of Personal Information:</p>
        <ol>
            <li>
                <p>Identifiers, such as name and government-issued identifier (e.g., Social Security number);</p>
            </li>
            <li>
                <p>Personal information, as defined in the California safeguards law, including without limitation, name, signature, SSN, physical characteristics or description, address, telephone number, passport number, driver&rsquo;s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information;</p>
            </li>
            <li>
                <p>Characteristics of protected classifications under California or federal law, including without limitation, age, gender, medical conditions, marital status, sex (including pregnancy, childbirth, breastfeeding, and related medical conditions), race, color, religion or creed, ancestry, national origin, disability, genetic information, AIDS/HIV status, sexual orientation, gender identity and expression, citizenship, primary language, immigration status, military/veteran status, political affiliation/activities, domestic violence victim status, and request for leave;</p>
            </li>
            <li>
                <p>Commercial information, such as transaction information and purchase history;</p>
            </li>
            <li>
                <p>Biometric information, such as face scans, fingerprints and voiceprints;</p>
            </li>
            <li>
                <p>Internet or network activity information, such as browsing history and interactions with our websites;</p>
            </li>
            <li>
                <p>Geolocation data, such as device location;</p>
            </li>
            <li>
                <p>Audio, electronic, visual and similar information, such as call and video recordings;</p>
            </li>
            <li>
                <p>Professional or employment-related information, such as work history and prior employer;</p>
            </li>
            <li>
                <p>Education information subject to the federal Family Educational Rights and Privacy Act, such as student records and directory information; and</p>
            </li>
            <li>
                <p>Inferences drawn from any of the Personal Information listed above to create a profile or summary about, for example, an individual&rsquo;s preferences and characteristics.</p>
            </li>
        </ol>
        <p>We collected this Personal Information directly from California residents themselves, as well as from publicly available databases and third-party sources of information. We collected Personal Information about our employees&rsquo; and other individuals&rsquo; emergency contacts and beneficiaries from such employees or other individuals.</p>
        <p>We may use this Personal Information to operate, manage, and maintain our business, for our employment purposes, and to otherwise accomplish our business purposes and objectives. Our business purposes and objectives include, for example, developing and improving our services and programs for our employees; conducting research, analytics, and data analysis; maintaining our facilities and infrastructure; undertaking quality and safety assurance measures; conducting risk and security controls and monitoring; detecting and preventing fraud; performing identity verification; performing accounting, audit, and other internal functions, such as internal investigations; complying with law, legal process, and internal policies; maintaining records; and exercising and defending legal claims.</p>
        <p>(2) We disclosed the following Personal Information to third parties, such as our service providers and affiliates, for our operational business purposes:</p>
        <ol>
            <li>
                <p>Identifiers, such as name and government-issued identifier (e.g., Social Security number);</p>
            </li>
            <li>
                <p>Personal information, as defined in the California safeguards law, including without limitation, name, signature, SSN, physical characteristics or description, address, telephone number, passport number, driver&rsquo;s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information;</p>
            </li>
            <li><p>Characteristics of protected classifications under California or federal law, including without limitation, age, gender, medical conditions, marital status, sex (including pregnancy, childbirth, breastfeeding, and related medical conditions), race, color, religion or creed, ancestry, national origin, disability, genetic information, AIDS/HIV status, sexual orientation, gender identity and expression, citizenship, primary language, immigration status, military/veteran status, political affiliation/activities, domestic violence victim status, and request for leave;</p></li>
            <li>
                <p>Commercial information, such as transaction information and purchase history;</p>
            </li>
            <li>
                <p>Biometric information, such as fingerprints and voiceprints;</p>
            </li>
            <li>
                <p>Internet or network activity information, such as browsing history and interactions with our websites;</p>
            </li>
            <li><p>Geolocation data, such as device location;</p></li>
            <li>
                <p>Audio, electronic, visual and similar information, such as call and video recordings;</p>
            </li>
            <li>
                <p>Professional or employment-related information, such as work history and prior employer;</p>
            </li>
            <li>
                <p>Education information subject to the federal Family Educational Rights and Privacy Act, such as student records and directory information; and</p>
            </li>
            <li>
                <p>Inferences drawn from any of the Personal Information listed above to create a profile or summary about, for example, an individual&rsquo;s preferences and characteristics.</p>
            </li>
        </ol>
        <p>(3) We have not &ldquo;sold&rdquo; Personal Information for purposes of the CCPA.</p>
        <p>For purposes of this CCPA Notice, &ldquo;sold&rdquo; or &ldquo;sale&rdquo; means the disclosure of Personal Information for monetary or other valuable consideration but does not include, for example, the transfer of Personal Information as an asset that is part of a merger, bankruptcy, or other disposition of all or any portion of our business.</p>
        <p>If you have questions regarding this Privacy Notice, please contact <a href="mailto:hello@bradient.com">hello@bradient.com</a></p>

        </div>
      </div>
    </Layout>
  )
}